body {
  font-family: 'Dunkin Lyons', sans-serif;
}

.App {
  text-align: center;
  height: 100vh;
  margin-bottom: 150px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.text-headline {
  font-size: 32px;
  font-weight: 400;
}

.text-subheadline {
  font-size: 20px;
  font-weight: 300;
}

.text-caption {
  font-size: 12px;
}

.fs-text-body {
  font-size: 14px;
}

.one-line {
  white-space: nowrap;
  overflow: hidden;
  display: block;
  text-overflow: ellipsis;
}

.connect-wallet {
  cursor: pointer;
  color: white;
  box-shadow: 4px 4px 14px rgba(0,0,0,0.05);
  width: fit-content;
  text-align: center;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding: 24px 32px;
  border-radius: 32px;
}

.connect-wallet:hover {
  background-image: url("https://media.giphy.com/media/3o72FhIuVWddxQHftS/giphy.gif");
  text-shadow: 1px 1px black;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
}

.About {
  text-align: center;
  align-items: center;
  justify-content: center;
  margin-top: 25px;
  width: auto;
  padding-left: 10%;
  padding-right: 10%;
  line-height: 1em;
  display: block;
}
